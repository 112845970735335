<i18n>
ru:
  agreementLink: с условиями пользовательского соглашения
  agreementText: 'Продолжая, вы соглашаетесь'
  alreadyRegistered: Уже есть аккаунт?
  alreadyRegisteredErr: Уже имеется пользователь с таким номером телефона!
  backToAuth: К авторизации
  bday: Дата рождения
  captchaSiteReloadError: Ошибка. Перезагрузите страницу и введите капчу снова
  captchaValidationError: Вы не прошли валидацию капчи
  changePhone: Изменить
  clientWasNotFound: >-
    Не найден гость с данным номером телефона. Попробуйте сначала
    зарегистрироваться.
  confirm: Подтвердить
  email: Эл. почта
  emailNotifyRequired: Укажите свой email-адрес для получения писем о спецпредложениях и новинках
  enterPassword: Пароль
  enterPhoneNumber: Телефон
  enterSentCode: Код из СМС
  flashcall: По звонку
  flashcallCode: Последние 4 цифры номера
  flashcallCodeHelp: 'введите последние четыре цифры номера, с которого поступает звонок.'
  flashcallHelp: >-
    Вам поступит звонок на указанный номер. Вам потребуется ввести последние 4
    цифры номера, с которого поступит звонок, в качестве кода
  flashcallHelpForInputSecond: 'Введите последние четыре цифры номера, с которого поступит звонок'
  flashcallHelpSecond: 'Мы позвоним на номер: '
  forgottenPass: Забыли пароль?
  gender: Пол
  genderFemale: Женский
  genderMale: Мужской
  genderNotSelected: Не выбрано
  genderRequired: Для регистрации необходимо указать пол
  getTheCode: 'Способ получения кода:'
  invalidMainContact: Введите корректный номер телефона!
  login: Войти
  name: Имя
  newPasswordText: Мы отправили Вам сообщение с новым паролем. Введите его
  noPermission: Для совершения данной операции необходимо авторизоваться
  notifyRequired: Для регистрации необходимо дать согласие на рассылку
  passwordDoesNotMatchPattern: >-
    Пароль должен состоять минимум из 6 символов, содержать одну букву верхнего
    регистра и одну цифру.
  passwordIsInvalid: Пароль введен неверно.
  passwordNew: Сбросить пароль
  passwordSuccessfullyChanged: Пароль успешно изменен!
  passwordsDoesNotMatch: Введённые пароли должны совпадать.
  personalDataMessage: 'Предоставляя свои персональные данные, Вы соглашаетесь с '
  personalDataMessageLink: условиями обработки персональных данных
  receiveEmail: Получать письма по e-mail о новинках и спецпредложениях
  receiveSms: Получать письма по sms о новинках и спецпредложениях
  register: Регистрация
  registerSuggestion: Нет аккаунта?
  requiredFieldsWasNotFilledIn: 'Пожалуйста, заполните все обязательные поля!'
  resetPass: Восстановить пароль
  sms: СМС
  smsCode: Код
  smsCodeHelp: 'Введите код, полученный по SMS.'
  smsHelp: Вы получите SMS с кодом на указанный номер
  smsHelpForInputSecond: 'Введите код, полученный в SMS'
  smsHelpSecond: 'Мы отправили код на номер: '
  smsLoginInfoText: ''
  surname: Фамилия
  telegram: Telegram
  telegramCode: Код из Telegram-бота
  telegramCodeHelp: Код бота Telegram
  telegramHelp: >-
    Вы будете перенаправлены в Telegram-бот, где нужно будет нажать /start и
    предоставить доступ к своему номеру телефона. После этого код поступит в
    Telegram-бот
  telegramHelpForInputSecondLeft: >-
    Перейдите в Telegram-бот по
  telegramHelpForInputSecondRight: >-
    , нажмите /start,
    предоставьте доступ к своему номеру телефона и введите полученный код
  telegramHelpForInputSecondLink: >-
    ссылке в новой вкладке
  telegramHelpSecond: 'Мы отправили код в Telegram на номер: '
  titleLogin: Войти в личный кабинет
  unknownError: >-
    Извините за неудобства, что-то пошло не так. Пожалуйста, свяжитесь с нашей
    службой поддержки для получения помощи.
  verificationFailed: 'Ошибка: код введен неверно или не подходит.'
  verifyMainContact: Получить код
  verifyMainContactLeft: 'Запросить новый код через {timer}'
ua:
  agreementLink: з умовами користувальницької угоди
  agreementText: 'Продовжуючи, ви погоджуєтесь'
  alreadyRegistered: Вже зареєстровані?
  alreadyRegisteredErr: Вже є користувач з таким номером телефону!
  backToAuth: Перейти до авторизації
  bday: Дата народження
  captchaSiteReloadError: Помилка. Перезавантажте сторінку та введіть капчу знову
  captchaValidationError: Ви не пройшли валідацію капчі
  changePhone: Змінити
  clientWasNotFound: >-
    Не знайдено гостя з даним номером телефону. Спробуйте спочатку
    зареєструватися.
  confirm: Підтверджувати
  email: Ел. пошта
  emailNotifyRequired: >-
    Введіть свою електронну адресу, щоб отримувати листи про спеціальні
    пропозиції та новинки
  enterPassword: Пароль
  enterPhoneNumber: Телефон
  enterSentCode: Код із СМС
  flashcall: По звонку
  flashcallCode: Останні 4 цифри номера
  flashcallCodeHelp: 'введіть останні чотири цифри номера, з якого надходить дзвінок.'
  flashcallHelp: >-
    Вам надійде дзвінок на вказаний номер. Вам потрібно буде ввести останні 4
    цифри номера, з якого надійде дзвінок, як код
  flashcallHelpForInputSecond: 'Введіть останні чотири цифри номера, з якого надійде дзвінок'
  flashcallHelpSecond: 'Ми подзвонимо на номер: '
  forgottenPass: Забули пароль?
  gender: Стать
  genderFemale: Жіноча
  genderMale: Чоловіча
  genderNotSelected: Не обрано
  genderRequired: Для реєстрації необхідно вказати стать
  getTheCode: 'Виберіть спосіб отримання коду:'
  invalidMainContact: Введить коректний номер телефону!
  login: Увійти
  name: Ім’я
  newPasswordText: Ми надіслали Вам повідомлення з новим паролем. Введіть його
  noPermission: Для здійснення цієї операціх необхідно авторизуватися
  notifyRequired: Для реєстрації необхідно погодитися на розсилку
  passwordDoesNotMatchPattern: >-
    Пароль має складатися мінімум з 6 символів, мати одну букву верхнього
    регістру та одну цифру.
  passwordIsInvalid: Пароль введено невірно.
  passwordNew: Скинути пароль
  passwordSuccessfullyChanged: Пароль успішно змінено!
  passwordsDoesNotMatch: Введені паролі повинні збігатися.
  personalDataMessage: 'Надаючи свої персональні дані, ви погоджуєтеся з '
  personalDataMessageLink: умовами обробки персональних даних
  receiveEmail: Отримувати листи по e-mail про новинки та спеціальні пропозиції
  receiveSms: Отримувати sms про новинки та спеціальні пропозиції
  register: Реєстрація
  registerSuggestion: Не маєте профілю?
  requiredFieldsWasNotFilledIn: 'Будь ласка, заповніть всі обов’язкові поля!'
  resetPass: Скинути пароль
  sms: СМС
  smsCode: Код
  smsCodeHelp: 'Введіть код, отриманий через SMS.'
  smsHelp: Ви отримаєте SMS із кодом на вказаний номер
  smsHelpForInputSecond: 'Введіть код, отриманий через SMS'
  smsHelpSecond: 'Ми надіслали код на номер: '
  smsLoginInfoText: ''
  surname: Прізвище
  telegram: Telegram
  telegramCode: Код із Telegram-бота
  telegramCodeHelp: Код бота Telegram
  telegramHelp: >-
    Ви будете перенаправлені до Telegram-боту, де потрібно буде натиснути /start
    та надати доступ до свого номера телефону. Після цього код надійде в
    Telegram-бот
  telegramHelpForInputSecondLeft: >-
    Перейдіть до Telegram-бота за
  telegramHelpForInputSecondRight: >-
    , натисніть /старт,
    надайте доступ до свого номера телефон і введіть отриманий код
  telegramHelpForInputSecondLink: >-
    посиланням у новій вкладці
  telegramHelpSecond: 'Ми надіслали код у Telegram на номер: '
  titleLogin: Увійти в Особистий кабінет
  unknownError: >-
    Вибачте за незручності, щось пішло не так. Будь ласка, зв'яжіться з нашою
    службою підтримки, щоб отримати допомогу.
  verificationFailed: 'Помилка: код введено невірно або не підходить.'
  verifyMainContact: Отримати код
  verifyMainContactLeft: 'Запит на новий код через {timer}'
us:
  agreementLink: to the terms of the user agreement
  agreementText: 'By continuing, you agree'
  alreadyRegistered: Already have an account?
  alreadyRegisteredErr: This phone number is already used by another user!
  backToAuth: Back to authorization
  bday: Date of birth
  captchaSiteReloadError: Error. Please refresh the page and try to enter captcha again
  captchaValidationError: Your captcha validation is uncompleted
  changePhone: Change
  clientWasNotFound: User with this phone number was not found
  confirm: Confirm
  email: Email
  emailNotifyRequired: >-
    Enter your email address to receive emails about special offers and new
    products
  enterPassword: Password
  enterPhoneNumber: Phone
  enterSentCode: Code from SMS
  flashcall: Via call
  flashcallCode: The last 4 digits of the number
  flashcallCodeHelp: Enter the last four digits of the number the call is coming from
  flashcallHelp: >-
    You will receive a call to the specified number. You will need to enter the
    last 4 digits of the number from which the call will come as a code
  flashcallHelpForInputSecond: Enter the last four digits of the number you are calling from
  flashcallHelpSecond: 'We will call the number: '
  forgottenPass: Forgot your password?
  gender: Gender
  genderFemale: Female
  genderMale: Male
  genderNotSelected: Not selected
  genderRequired: Gender is required for registration
  getTheCode: 'Choose how to get the code:'
  invalidMainContact: Please enter the correct phone number!
  login: Sign in
  name: Name
  newPasswordText: We have sent you a message with a new password. Enter it
  noPermission: You should be authorized to do this operation
  notifyRequired: 'To register, consent to receiving newsletters is required'
  passwordDoesNotMatchPattern: >-
    Password should consist minimum of 6 symbols, contain one uppercase letter
    and one number.
  passwordIsInvalid: Password is incorrect.
  passwordNew: Reset password
  passwordSuccessfullyChanged: Password changed successfully!
  passwordsDoesNotMatch: Entered passwords does not match.
  personalDataMessage: 'By submitting your personal data, you agree to '
  personalDataMessageLink: the terms of personal data processing
  receiveEmail: Subscribe to e-mail newsletter
  receiveSms: Subscribe to SMS newsletter
  register: Register
  registerSuggestion: Don’t have an account?
  requiredFieldsWasNotFilledIn: Please fill in all the required fields!
  resetPass: Reset password
  sms: SMS
  smsCode: Code
  smsCodeHelp: Enter the code received via SMS
  smsHelp: You will receive an SMS with a code to the specified number
  smsHelpForInputSecond: Enter the code received via SMS
  smsHelpSecond: 'We have sent a code to the number: '
  smsLoginInfoText: ''
  surname: Surname
  telegram: Telegram
  telegramCode: Code from the Telegram bot
  telegramCodeHelp: Code from the Telegram bot
  telegramHelp: >-
    You will be redirected to the Telegram bot, where you will need press /start
    and provide access to your phone number. After this, the code will be sent
    to the Telegram bot
  telegramHelpForInputSecondLeft: >-
    Open link to Telegram bot
  telegramHelpForInputSecondRight: >-
    , press /start, provide access to your
    number phone and enter the received code
  telegramHelpForInputSecondLink: >-
    in new tab
  telegramHelpSecond: 'We sent the code to Telegram to the number: '
  titleLogin: Log in to your account
  unknownError: >-
    We apologize for the inconvenience. Something went wrong. Please contact our
    support team for assistance.
  verificationFailed: 'Error: wrong code entered, please try again.'
  verifyMainContact: Request code
  verifyMainContactLeft: 'Request a new code in {timer}'
</i18n>

<template>
  <div class="v-account-management">
    <transition
      appear
      mode="out-in"
      name="fade"
    >
      <account-login
        v-if="mode === LKMode.Login"
        :auth-variant-code="authVariantCode"
        :auth-variant-text="authVariantText"
        :only-by-sms="onlyBySms"
        v-model:auth-variant="authVariant"
        v-model:mode="mode"
        v-model:phone="phone"
      />
      <account-register-first-stage
        v-else-if="mode === LKMode.RegisterFirstStage || mode === LKMode.LoginBySms"
        :auth-variant-code="authVariantCode"
        :auth-variant-text="authVariantText"
        :only-by-sms="onlyBySms"
        v-model:auth-variant="authVariant"
        v-model:fields="registerFields"
        v-model:mode="mode"
        v-model:phone="phone"
      />
      <account-register-second-stage
        v-else-if="mode === LKMode.RegisterSecondStage || mode === LKMode.LoginBySmsSecondStage"
        :auth-variant-code="authVariantCode"
        :auth-variant-text="authVariantText"
        :only-by-sms="onlyBySms"
        v-model:auth-variant="authVariant"
        v-model:fields="registerFields"
        v-model:mode="mode"
        v-model:phone="phone"
      />
      <account-request-new-pass-first-stage
        v-else-if="mode === LKMode.RequestNewPassFirstStage"
        :auth-variant-code="authVariantCode"
        :auth-variant-text="authVariantText"
        :only-by-sms="onlyBySms"
        v-model:auth-variant="authVariant"
        v-model:fields="resetFields"
        v-model:mode="mode"
        v-model:phone="phone"
      />
      <account-request-new-pass-second-stage
        v-else-if="mode === LKMode.RequestNewPassSecondStage"
        :auth-variant-code="authVariantCode"
        :auth-variant-text="authVariantText"
        :only-by-sms="onlyBySms"
        v-model:auth-variant="authVariant"
        v-model:fields="resetFields"
        v-model:mode="mode"
        v-model:phone="phone"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { AuthType, AuthVariant, LKMode } from '~api/consts'

import type { RegisterPayload, ResetPayload } from '~types/accountStore'

const { translate } = useI18nSanitized()

const appConfig = useAppConfig()
const phoneForLogin = useCookie('phoneForLogin')

const onlyBySms = ref<boolean>(appConfig.RestaurantSettingsPreRun.AuthType === AuthType.EmbeddedBySms)

const mode = ref<number>(onlyBySms.value ? LKMode.LoginBySms : LKMode.Login)
const phone = ref<string>(phoneForLogin.value?.toString() ?? '')

const authVariant = ref<AuthVariant | null | undefined>(
  appConfig.RestaurantSettingsPreRun.AuthVariants.length > 1
    ? null
    : appConfig.RestaurantSettingsPreRun.AuthVariants[0]
)

function authVariantCode(authVariant: AuthVariant): string {
  switch (authVariant) {
    case AuthVariant.Sms: {
      return translate('accountManagement.enterSentCode')
    }
    case AuthVariant.Telegram: {
      return translate('accountManagement.telegramCode')
    }
    case AuthVariant.Flashcall: {
      return translate('accountManagement.flashcallCode')
    }
  }

  return translate('accountManagement.enterSentCode')
}

function authVariantText(authVariant: AuthVariant): string {
  switch (authVariant) {
    case AuthVariant.Sms: {
      return translate('accountManagement.sms')
    }
    case AuthVariant.Telegram: {
      return translate('accountManagement.telegram')
    }
    case AuthVariant.Flashcall: {
      return translate('accountManagement.flashcall')
    }
  }

  return translate('accountManagement.sms')
}

const resetFields = ref<ResetPayload>({
  externalAuthLink: null,
  code: null as string | null,
  codeMaxLength: 6,
  country: '',
  ignorePhoneCheck: false, //for iiko card
  passedV3: true,
  phone: null as string | null,
  recaptcha: null as string | null,
  smartcaptcha: null as string | null
})

const registerFields = ref<RegisterPayload>({
  externalAuthLink: null,
  bday: null as string | null,
  code: null as string | null,
  codeMaxLength: 6,
  country: '',
  email: null as string | null,
  ignorePhoneCheck: true, //for iiko card
  male: false,
  maleIsNull: true,
  name: null as string | null,
  passedV3: true,
  phone: null as string | null,
  recaptcha: null as string | null,
  receiveEmail: true, //set by default
  receiveSms: true, //set by default
  smartcaptcha: null as string | null,
  surname: null as string | null
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-account-management {
  color: variables.$PopupColor;

  &--title {
    font-weight: 600;
    font-size: variables.$TextSizeH3;

    display: flex;
    align-items: center;

    svg {
      width: variables.$TextSizeH2;
      height: variables.$TextSizeH2;
      fill: none;
    }
  }
  &--subtitle {
    color: variables.$BodyTextColorLight;
    font-size: 0.8rem;
    display: flex;
    gap: 4px;
    .v-phone-user {
      font-weight: bold;
      color: variables.$BodyTextColor;
    }
  }
}

.v-btn-link {
  &.v-btn-login-link {
    font-size: 0.9rem;

    span {
      text-decoration: underline;
    }
  }
}
.v-row-auth-variants {
  column-gap: clamp(0.625rem, 0.3977rem + 1.1364vw, 1.25rem);
  row-gap: clamp(0.225rem, 0.3977rem + 1.1364vw, 0.8rem);
  .v-col-auth-variant {
    flex: 1 0 0%;
  }
  .v-auth-variants-1 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
.v-account-management {
  .v-get-the-code {
    font-size: 1.125rem;
    font-weight: 600;
  }
  .v-personal-data * {
    font-size: 0.8rem;
  }
  .v-auth-type--help {
    &__light {
      font-size: 0.8rem;
      display: flex;
      flex-direction: row;
      color: variables.$BodyTextColorLight;
      gap: 4px;

      .v-link {
        font-size: 0.8rem;
      }

      .v-icon {
        flex: 0 0 20px;
        max-width: 20px;
        margin-right: 4px;
        svg {
          fill: none;
          stroke {
            fill: variables.$BodyTextColorLight;
          }
        }
      }
      .v-text {
        flex: 0 0 calc(100% - 24px);
        max-width: calc(100% - 24px);
      }
    }
    &__main {
      font-size: 0.9rem;
      color: variables.$BodyTextColorLight;
    }
  }
  .v-btn-verify-main-contact {
    padding: 10px 16px;
  }
  .v-btn-auth-variant {
    display: flex;
    flex-direction: column;
    background: variables.$FormBackground;
    color: variables.$BodyTextColor;
    padding: 16px;
    font-size: 1rem;
    //
    margin-right: 0;
    min-height: auto;
    margin-bottom: 0;
    .v-icon {
      width: 28px;
      height: 28px;
      display: inline-flex;
      margin-bottom: 4px;
      .v-icon-message,
      .v-icon-telegram,
      .v-icon-phone {
        path {
          fill: variables.$BodyTextColor;
        }
      }
    }
    @include mixins.sm {
      padding: 6px;
      font-size: 0.7rem;
      .v-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  input[type='radio'] {
    display: none;

    &:checked + .v-btn-auth-variant {
      display: flex;
      flex-direction: column;
      background: variables.$BodyTextColor;
      color: variables.$FormBackground;
      .v-icon {
        .v-icon-message,
        .v-icon-telegram,
        .v-icon-phone {
          path {
            fill: variables.$FormBackground;
          }
        }
      }
    }
  }
}
</style>
